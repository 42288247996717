<template>
    <div class="w-full h-full flex flex-col items-center justify-start relative">

        <div class="h-auto absolute top-0 left-0 mt-8 ml-2 flex flex-row justify-center items-center" @click="exit()">
            <i class="mdi mdi-arrow-left text-2xl text-red z-10"></i>
        </div>

        <span class="text-red text-lg mt-10 font-semibold mb-2">{{$t('legalRequirements')}}</span>

        <div class="h-100 w-10/12 shadow-inner overflow-auto mb-4 shadow-card p-4 text-sm rounded-lg ">

            <p class="text-gray text-left mb-2">
                {{$t('legalRequirementsTextOne')}}
            </p>

            <p class="text-gray text-left mb-2">
                {{$t('legalRequirementsTextTwo')}}
            </p>

            <p class="text-gray text-left mb-2">
                {{$t('legalRequirementsTextThree')}}
            </p>

            <p class="text-gray text-left mb-2">
                {{$t('legalRequirementsTextFour')}}
            </p>

            <p class="text-gray text-left mb-2">
                {{$t('legalRequirementsTextFive')}}
            </p>

            <p class="text-gray text-left mb-2">
                {{$t('legalRequirementsTextSix')}}
            </p>

            <p class="text-gray text-left mb-2">
                {{$t('legalRequirementsTextSeven')}}
            </p>

            <p class="text-gray text-left mb-2">
                {{$t('legalRequirementsTextEight')}}
            </p>

            <p class="text-gray text-left mb-2">
                {{$t('legalRequirementsTextNine')}}
            </p>

            <p class="text-black text-left mb-2">
                {{$t('legalRequirementsTextTen')}}
            </p>

        </div>

        <!-- <div class="h-10 w-10/12 flex flex-row justify-start items-center mb-auto">
            <input type="checkbox" v-model="accepted" class="mr-2 form-checkbox text-red shadow-card">
            <span class="text-black text-left">He Leído y Acepto las Condiciones Legales</span>
        </div> -->

        <div class="flex-1 w-full flex flex-col justify-start items-center">

            <Request
                model="Changeme"
                action="update"
                :form="form"
                @success="onSuccess"
                @error="onError"
                v-slot="{request, loading}">

                <div @click="request" :class="{'opacity-50': loading }" :disabled="loading" class="h-10 w-10/12 rounded-lg bg-red flex flex-row justify-center items-center mb-10">
                    <span class="text-white">{{$t('readAndAccept')}}</span>
                </div>
            </Request>

        </div>

    </div>
</template>

<script>
    import { Request } from '@/api/components';
    import { routeByUser } from '@/router/helpers';
    import { Auth } from '@/api/models';
    import { actions, state } from '@/store';

    export default {
        name: 'Login',
        components: {
            Request
        },
        data() {
            return {
                accepted: false,
                form:{
                    Id: state.user.id,
                    firstlogin: 0
                }
            };
        },
        computed: {
            user() {
                return state.user;
            }
        },
        methods: {
            async onSuccess(){
                const user = await Auth.me();
                actions.setUser(user);

                const route = routeByUser(user);
                this.$router.push(route);
            },

            onError(error){
                this.$message.error(error)
            },

            exit(){
                actions.resetAuth();
                this.$router.push({name: 'login'})
            }
        },
        mounted() {
            const route = routeByUser(this.user);
            this.$router.push(route);
        }
    };
</script>
